<template>
    <v-container fluid class="mb-2 white">
        <v-row>
            <v-col md="1" class="px-5">
                <v-row>
                    <v-text-field
                        label="Horaire"
                        type="time"
                        :value="teachingProcessElement ? durationToInput(teachingProcessElement.startingTime) : '00:00'"
                        readonly
                    ></v-text-field>
                </v-row>
                <v-row>
                    <v-text-field
                        label="Durée"
                        type="time"
                        :value="teachingProcessElement ? durationToInput(teachingProcessElement.duration) : '00:00'"
                        @change="updateValue('duration', $event)"
                        @focus="resetDebounce(false)"
                        @blur="resetDebounce(true)"
                        ref="duration"
                    ></v-text-field>
                </v-row>
            </v-col>
            <v-col md="6" class="px-5">
                <v-row>
                    <v-textarea
                        label="Objectifs"
                        @change="updateValue('objective', $event)"
                        @focus="resetDebounce(false)"
                        @blur="resetDebounce(true)"
                        :value="teachingProcessElement ? teachingProcessElement.objective : ''"
                        rows="1"
                        auto-grow
                        ref="objective"
                    ></v-textarea>
                </v-row>
                <v-row>
                    <v-textarea
                        label="Activités formateur"
                        @change="updateValue('activities', $event)"
                        @focus="resetDebounce(false)"
                        @blur="resetDebounce(true)"
                        :value="teachingProcessElement ? teachingProcessElement.activities : ''"
                        rows="1"
                        auto-grow
                        ref="activities"
                    ></v-textarea>
                </v-row>
            </v-col>
            <v-col md="5" class="px-5">
                <v-row>
                    <v-col class="px-5">
                        <v-row>
                            <v-select
                                label="Qui ?"
                                :items="['Apprenant', 'Enseignant', 'Formateur', 'Tuteur', 'Coach', 'Mentor']"
                                @change="updateValue('who', $event)"
                                @focus="resetDebounce(false)"
                                @blur="resetDebounce(true)"
                                :value="teachingProcessElement ? teachingProcessElement.who : ''"
                                ref="who"
                            ></v-select>
                        </v-row>
                    </v-col>
                    <v-col class="px-5">
                        <v-row>
                            <v-select
                                label="Matériel / Support"
                                :items="['Jeu', 'Wiki', 'E-Book', 'Livre', 'Atelier', 'Mind-Map', 'Feedback', 'Évaluation', 'Restitution', 'Étude de cas', 'Jeu de rôle', 'Prise de notes', 'Recherche web', 'Démonstration', 'Vidéo', 'Échange de poste', 'Réalité virtuelle', 'Réalité augmentée', 'Évaluation formative', 'Documents entreprise', 'Production de livrable']"
                                @change="updateValue('support', $event)"
                                @focus="resetDebounce(false)"
                                @blur="resetDebounce(true)"
                                :value="teachingProcessElement ? teachingProcessElement.support : ''"
                                ref="support"
                            ></v-select>
                        </v-row>
                    </v-col>
                    <v-col class="px-5">
                        <v-row>
                            <v-select
                                label="Méthode"
                                :items="['Collaborative', 'Coopérative', 'Autonomie', 'Transmissive', 'Déductive', 'Inductive']"
                                @change="updateValue('method', $event)"
                                @focus="resetDebounce(false)"
                                @blur="resetDebounce(true)"
                                :value="teachingProcessElement ? teachingProcessElement.method : ''"
                                ref="method"
                            ></v-select>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-textarea
                        class="px-2"
                        label="Evaluation"
                        @change="updateValue('evaluation', $event)"
                        @focus="resetDebounce(false)"
                        @blur="resetDebounce(true)"
                        :value="teachingProcessElement ? teachingProcessElement.evaluation : ''"
                        rows="1"
                        auto-grow
                        ref="evaluation"
                    ></v-textarea>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import debounce from "debounce";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "TeachingProcessElementLine",

    props: {
        teachingProcessElementId: {
            type: String,
            require: false,
        },
        teachingProcessId: {
            type: String,
            require: false,
        },
    },

    data() {
        return {
            launched: false,
        }
    },

    computed: {
        teachingProcessElement: function() {
            return this.teachingProcessElementId ? this.getTeachingProcessElementById()(this.teachingProcessElementId) : null;
        },
    },

    created() {
        this.debouncedCreate = debounce(() => {
            this.createElement();
        }, 1000);
    },

    methods: {
        ...mapActions("formations", ["modifyTeachingProcessElement", "addTeachingProcessElement"]),

        ...mapGetters("formations", ["getTeachingProcessElementById"]),

        resetDebounce: function(set) {
            if(set) {
                // onBlur
                if(this.launched) {
                    this.debouncedCreate();
                }
            } else {
                // onFocus
                this.$nextTick(() => {
                    this.debouncedCreate.clear();
                });
            }
        },

        updateValue: async function(element, value) {
            if(!this.teachingProcessElement) {
                this.launched = true;
                await this.debouncedCreate();
                return;
            }

            if (element === "duration") {
                value = this.durationToNumber(value);
            }

            await this.modifyTeachingProcessElement({
                teachingProcessElementId: this.teachingProcessElementId,
                [element]: value,
            });

            this.$emit("update");
        },

        createElement: async function() {
            const element = {};

            for(const item in this.$refs) {
                if (item === "duration") {
                    element[item] = this.durationToNumber(this.$refs[item].lazyValue);
                    this.$refs[item].lazyValue = "00:00";
                } else {
                    element[item] = this.$refs[item].lazyValue;
                    this.$refs[item].lazyValue = "";
                }

                this.$nextTick(() => {
                    this.$refs[item].blur();
                });
            }

            await this.addTeachingProcessElement({ id: this.teachingProcessId, element: element })
            
            this.launched = false;
            this.$emit("create");
        },

        durationToNumber: function(value) {
            const [hours, minutes] = value.split(":");
            return Number(hours) * 60 + Number(minutes);
        },

        durationToInput: function(value) {
            let hours = ~~(value / 60);
            let minutes = value % 60;

            if (hours < 10) {
                hours = "0" + hours;
            }

            if (minutes < 10) {
                minutes = "0" + minutes;
            }

            return hours + ":" + minutes;
        },
    },
};
</script>

<style scoped>
.container {
    border-bottom: black 2px solid !important;
}
</style>
