<template>
    <v-row class="subtitle-1">
        <v-col class="px-0">
            <v-row class="text-center my-1 lightgreen lighten-1 mx-0">
                <v-col md="1">Horaires</v-col>
                <v-col md="6">Objectifs et activités de l'étape</v-col>
                <v-col>Evaluation</v-col>
                <v-col></v-col>
                <v-col></v-col>
            </v-row>

            <teaching-process-element-line
                    v-for="element in elements"
                    :key="element.id"
                    :teaching-process-element-id="element.id"
                    @update="getTeachingProcessElements"
            ></teaching-process-element-line>

            <teaching-process-element-line
                    @create="getTeachingProcessElements"
                    :teaching-process-id="teachingProcess.id"
            ></teaching-process-element-line>

        </v-col>
    </v-row>
</template>

<script>
import TeachingProcessElementLine from "@/components/designer/TeachingProcessElementLine";
import { mapActions, mapState } from "vuex";

export default {
    name: "TeachingProcess",

    components: { TeachingProcessElementLine },

    props: {
        teachingProcess: {
            type: Object,
            require: true,
        }
    },

    computed: {
        ...mapState("formations", ["teachingProcessElements"]),

        elements: function() {
            return this.teachingProcessElements.filter(element => element.teachingProcessId === this.teachingProcess.id)
        }
    },

    methods: {
        ...mapActions("formations", [
            "getTeachingProcessElements",
        ]),
    },
}
</script>

<style scoped>

</style>