<template>
    <v-container fluid>
        <page-banner links="Ma formation/Déroulé séquence"></page-banner>
        <formation-header></formation-header>

        <v-row class="mt-10">
            <v-expansion-panels flat tile accordion>
                <v-expansion-panel v-for="(teachingProcess, index) in teachingProcesses" :key="teachingProcess.id" class="mb-2">
                    <v-expansion-panel-header color="lightgreen" class="subtitle-1 py-0">
                        <v-col md="1">
                            <v-icon>fas fa-sort-amount-down-alt</v-icon>
                        </v-col>
                        <v-col md="5" class="ml-n16">
                            <CustomInput
                                type="teaching"
                                :objectId="teachingProcess.id"
                                valueName="title"
                                :background="false"
                            ></CustomInput>
                        </v-col>
                        <v-col offset-md="3" md="1">
                            {{ durations[index] | durationToHuman }}
                        </v-col>
                        <v-col md="1">
                            <download-button download-type="teaching" :object-id="teachingProcess.id"></download-button>
                        </v-col>
                        <v-col md="1">
                            <v-btn
                                icon
                                class="hoverBtn"
                                @click.stop="
                                        dialogDelete = true;
                                        askForDelete = teachingProcess.id;
                                    ">
                                <v-icon>fas fa-times</v-icon>
                            </v-btn>
                        </v-col>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content color="background">
                        <teaching-process :teaching-process="teachingProcess"></teaching-process>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>

        <v-row class="mt-5">
            <v-btn icon @click="newTeaching"><v-icon color="black">fas fa-plus</v-icon></v-btn>
        </v-row>

        <v-dialog v-model="dialogNew" max-width="400">
            <v-card>
                <v-card-title>Nouveau déroulé séquence</v-card-title>
                <v-card-text><v-text-field label="Nom du déroulé séquence" v-model="newName"></v-text-field></v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error darken-1"
                        text
                        @click="
                            dialogNew = false;
                            newName = '';
                        "
                    >
                        Annuler
                    </v-btn>

                    <v-btn
                        color="success darken-2"
                        text
                        @click="
                            dialogNew = false;
                            newTeaching(true);
                        "
                    >
                        Créer le déroulé
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="400">
            <v-card>
                <v-card-title>Suppression</v-card-title>
                <v-card-text>Êtes vous sûr de vouloir supprimer ce déroulé séquence ?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="
                            dialogDelete = false;
                            askForDelete = null;
                        "
                    >
                        Annuler
                    </v-btn>

                    <v-btn
                        color="error darken-2"
                        text
                        @click="
                            dialogDelete = false;
                            deleteTeaching();
                        "
                    >
                        Supprimer
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import FormationHeader from "@/components/designer/FormationHeader";
import PageBanner from "@/components/PageBanner";
import TeachingProcess from "@/components/designer/TeachingProcess";
import CustomInput from "@/components/CustomInput";
import DownloadButton from "../../../components/designer/DownloadButton.vue";

export default {
    name: "TeachingProcesses",

    components: {
        TeachingProcess,
        PageBanner,
        FormationHeader,
        CustomInput,
        DownloadButton,
    },


    created() {
        this.getTeachingProcesses();
        this.getTeachingProcessElements();
    },

    data: () => ({
        dialogNew: false,

        dialogDelete: false,
        confirmDelete: "",
        askForDelete: "",

        newName: "",
    }),

    computed: {
        ...mapState("settings", ["user"]),
        ...mapState("formations", ["currentFormation", "teachingProcesses", "teachingProcessElements"]),

        teachingProcessId() {
            return this.teachingProcesses[this.currentPanel];
        },

        durations: function() {
            const durations = [];

            for(const index in this.teachingProcesses) {
                const elements = this.teachingProcessElements.filter(element => element.teachingProcessId === this.teachingProcesses[index].id)
                if(elements.length > 0) {
                    durations.push(elements[elements.length - 1].startingTime + elements[elements.length - 1].duration);
                }
            }

            return durations;
        }
    },

    methods: {
        ...mapActions("formations", [
            "getTeachingProcessElements",
            "getTeachingProcesses",
            "addTeachingProcess",
            "removeTeachingProcess",
            "addTeachingProcessElement",
        ]),

        deleteTeaching() {
            this.removeTeachingProcess(this.askForDelete);
        },

        async newTeaching(toCreate) {
            if (toCreate === true) {
                await this.addTeachingProcess(this.newName);
                await this.getTeachingProcessElements();
                this.newName = "";
            } else {
                this.dialogNew = true;
            }
        },
    },
};
</script>

<style scoped>
.v-card__text > .row:first-child {
    border-bottom: 1px grey solid;
}

.v-expansion-panel >>> .v-expansion-panel-content__wrap {
    padding-left: 12px;
    padding-right: 12px;
}
</style>
